import React, { Fragment , useEffect, useReducer, useState } from 'react';
import './style.scss';
import { userGetMethod } from '../../../../api/userAction';
import { GRINDING_SCHEDULE_PENDING } from '../../../../api/userUrl';
import GrindingScheduleHeader from './GrindingScheduleHeader';

const GrindingSchedule = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [grindingSchedules, setGrindingSchedules] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    const rowHead = {
        fontSize:"15px",fontWeight:"bold"
    }
    const columnHead = {
        fontSize:"12px",fontWeight:"bold"
    }
    useEffect(()=>{
        userGetMethod(`${GRINDING_SCHEDULE_PENDING}`) 
        .then(response => {
            console.log('response', response.data);
            setGrindingSchedules(response.data.pendingGrindings);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className=""> 
                            <GrindingScheduleHeader grindingSchedules={grindingSchedules}/>

                            <div className="mainDiv" style={{"padding": "1% 5%"}}>
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <>
                                        <div className="text-center">
                                            <h5>Pending Grinding Schedule</h5>
                                        </div>
                                        <Fragment>
                                            <div className="row">
                                                <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                                    <thead>    
                                                    <tr>
                                                    <th scope="col" width="5%" > SL.</th>
                                                    <th scope="col" width="10%" ><i className="fa fa-sort"></i> Job No.</th>
                                                    <th scope="col" width="10%" ><i className="fa fa-sort"></i> Agreement Date </th>
                                                    <th scope="col" width="30%" ><i className="fa fa-sort"></i> Job Name</th>
                                                    <th scope="col" width="10%"> Qyt<span> (Done:Total)</span></th>
                                                    <th scope="col" width="10%" ><i className="fa fa-sort"></i> Type</th>                                                        
                                                    <th scope="col" width="15%" ><i className="fa fa-sort"></i> Client</th>
                                                    
                                                </tr>   
                                                    </thead>
                                                    <tbody>
                                                        {grindingSchedules.length > 0 ?
                                                            grindingSchedules.map((grindingSchedule,index)=>(    
                                                                <tr key={index}>
                                                                        <td scope="row">{ (index+1)  }</td>
                                                                        <td>{grindingSchedule.job_no}</td>
                                                                        <td>{grindingSchedule.agreement_date}</td>
                                                                        <td>{grindingSchedule.job_name}</td>
                                                                        <td>{grindingSchedule.done_cyl_qty} : {grindingSchedule.total_cylinder_qty}</td>
                                                                        <td>{grindingSchedule.job_type}</td>
                                                                        <td>{grindingSchedule.client_name}</td>
                                                                    </tr>  
                                                            ))
                                                        : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>    
                                        </Fragment>
                                    </>
                                )
                            } 
                            </div>  
                        </div>    
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default GrindingSchedule;