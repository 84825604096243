import React from 'react'
import ShiftControl from '../../../common/ShiftControl'

const GrindingShift = () => {
  return (
    <div>
        <ShiftControl value='grinding_shift'/>
    </div>
  )
}

export default GrindingShift