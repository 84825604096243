import React from "react";
import './style.scss';
import exportFromJSON from 'export-from-json'
import { FaFileCsv } from "react-icons/fa";

const PendingPlatingScheduleHeader = (props) => {
    const printDocument = () => {
        window.print();
    }
    const handleExportCsv = () => {
        const data = props.pendingPlatingSchedules;
        const fileName = 'Pending_Plating_Schedules'
        const exportType =  exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType })
        
    }
  return (
    <div className="report-header">
    <div className="print_button">
        <button className="btn btn-default" onClick={printDocument}><i className="fa fa-print" aria-hidden="true"></i> Print</button>&nbsp;
        <button className="btn btn-default mr-2"><i className="fa fa-file-pdf-o" aria-hidden="true"></i>Pdf</button>
        <button className="btn btn-default" onClick={handleExportCsv}><FaFileCsv /> CSV</button>
    </div>
    <div className="company-info">
        <img className="img-responsive" src={process.env.PUBLIC_URL+'/digitalLogo.png'} alt="Company Logo"/>
        <div className="company-name text-left">
            <h1>Digital Engravers Ltd</h1>
            <span className="company-moto">53 Purana Paltan (6th Floor)</span>
        </div>
    </div>
    <div className="report-for">
        <h2>Pending Plating Dept Schedules</h2>
    </div>
</div>
  )
}

export default PendingPlatingScheduleHeader

