import React from "react";
import logo from '../../../../assets/images/logo.png';
import './style.scss';


const ReportHeader = props => {
    const printDocument = () => {
        window.print();
    }
    return (
        <div className="report-header">
            <div className="print_button">
                <button className="btn btn-default" onClick={printDocument}><i className="fa fa-print" aria-hidden="true"></i> Print</button>&nbsp;
                <button className="btn btn-default"><i className="fa fa-file-pdf-o" aria-hidden="true"></i>Pdf</button>
            </div>
                
                                <div className="col-6">
                                        <div className="company-section">
                                        <img className="img-responsive challanImg" src={process.env.PUBLIC_URL+'/digitalLogo.png'} alt="Company Logo"/>
                                        
                                            <div>
                                                <div className="text-left">
                                                <p className='text_company'>
                                                    <span className="Digital_text">DIGITAL</span> ENGRAVERS LTD
                                                </p>
                                                <p className='mb-0 ml-2'><i className='company_italic_text'>Image transfer on Roto-Gravure Cylinder</i></p>
                                                </div>
                                                <section className="company_address text-left ml-2">Baitul Abed 1(6th Floor) 53, Purana Palan,Dhaka-10, Bangladesh</section>
                                                <section className="company_address text-left ml-2">Phone :02-223381145, 223389923 47123393</section>
                                                <section className="company_address text-left ml-2">Fax:88-02-223382552, E-maill:info@delbd.com</section>
                                                <div>

                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                               
            <div className="report-for">
                <h2>{ props.reportTitle }</h2>
            </div>
        </div>
)};

export default ReportHeader;