import React, { Fragment , useEffect, useReducer, useState } from 'react';
import './style.scss';
import { BASE_RECEIVE_SCHEDULE_PENDING } from '../../../api/userUrl';
import BaseReceiveScheduleHeader from './BaseReceiveScheduleHeader';
import { userGetMethod } from '../../../api/userAction';

const BaseReceiveSchedule = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [baseReceiveSchedules, setBaseReceiveSchedules] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    const rowHead = {
        fontSize:"15px",fontWeight:"bold"
    }
    const columnHead = {
        fontSize:"12px",fontWeight:"bold"
    }
    useEffect(()=>{
        userGetMethod(`${BASE_RECEIVE_SCHEDULE_PENDING}`) 
        .then(response => {
            console.log('response', response.data);
            setBaseReceiveSchedules(response.data.pendingbaseReceiveList);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
  return  <Fragment>
  <div className="container-fluid">
      <div className="row">
          <div className="col-sm-12">
              <div className=""> 
                  <BaseReceiveScheduleHeader baseReceiveSchedules={baseReceiveSchedules}/>

                  <div className="mainDiv" style={{"padding": "1% 5%"}}>
                  {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                      (
                          <>
                              <div className="text-center">
                                  <h5>Pending Quality Control Schedule</h5>
                              </div>
                              <Fragment>
                                  <div className="row">
                                      <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                          <thead>    
                                          <tr>
                                          <th scope="col" width="5%" >SL.</th>
                                                    {/* <th scope="col" width="5%" onClick={() => sortHandler(1)} ><i className="fa fa-sort"></i> SL.</th> */}
                                                    <th scope="col" width="15%" > Job No.</th>
                                                    <th scope="col" width="15%" > Job Name</th>
                                                    <th scope="col" width="10%" > Type</th>                                                        
                                                    <th scope="col" width="15%" > Client</th>
                                          
                                          </tr>   
                                          </thead>
                                          <tbody>
                                              {baseReceiveSchedules.length > 0 ?
                                                  baseReceiveSchedules.map((baseReceiveSchedule,index)=>(    
                                                      <tr key={index}>
                                                              <td scope="row">{ (index+1)} </td>
                                                                        <td>{baseReceiveSchedule.job_no}</td>
                                                                        <td>{baseReceiveSchedule.job_name}</td>
                                                                        <td>{baseReceiveSchedule.job_type}</td>
                                                                        <td>{baseReceiveSchedule.client_name}</td>
                                                          </tr>  
                                                  ))
                                              : null
                                              }
                                          </tbody>
                                      </table>
                                  </div>    
                              </Fragment>
                          </>
                      )
                  } 
                  </div>  
              </div>    
          </div>
      </div>
  </div>
</Fragment>
}

export default BaseReceiveSchedule




