import React, { Fragment, useEffect, useState, useReducer } from 'react';
import { POLISHING_RS_URL, POLISHING_GET_POLISHING_DATA_BY_JOB_ID, PREVIEW_URL_POLISHING, ENGRAVING_REASON } from '../../../../api/userUrl';
import { userGetMethod, userPutMethod } from '../../../../api/userAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  useForm from 'react-hook-form';
import { SubmitButton } from '../../../common/GlobalButton';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './edit.css'
import { useHistory } from 'react-router-dom';

const Edit = (props) => {
    const { handleSubmit, register, errors, reset,control } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [typeHeadOptions, setTypeHeadOptions] = useState({});
    const [roughCut, setRoughCut] = useState({});
    const [fineCut, setFineCut] = useState({});
    const [reasons, setReasons] = useState([]);
    const [dropDownData, setDropdownData] = useState();
    const [openReason,setOpenReason] = useState(false)
    const [durationTimeValue, setDurationTimeValue] = useState('');
    let [stateData, setStateData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
             
            polishing_cylinder_auto_id    : '', 
            job_order_id                  : '',
            cylinder_id                   : '',
            rough_cut_polishing_machine_id: '',
            shift_id                      : '',
            fine_cut_polishing_machine_id : '',
            est_duration                  : '',
            on_time                       : '',
            est_end_time                  : '',
            polishing_date                : '',
            rework                        : '',
            rework_reason                 : '',
            production_date               : '',
            done_by                       : '',
            chrome_cylinder_status        : '',
            a_duration                    : '',
            dia_after_rough_cut           : '',
            output_status                 : '',
            dia_after_fine_cut            : '',
            action_if_output_is_not_ok    : '',
            a_off_time                    : '',
            remarks                       : '',
            rework_reason_id              : '',
            
            job_order_pk_id                  : '', 
            jobOrderDetailsData           : [], //STORE DATA FROM job_orders
            shiftData                     : [], //STORE DATA FROM dig_shift_master
            shiftDutyPersons              : [], //STORE DATA FROM dig_shift_details
            reworkReasons                 : [], //STORE DATA FROM rework_reasons
            cylindersByJobId              : [], //STORE DATA FROM factory_cylinder_supply_chains
            completePolishingData         : [], //STORE DATA FROM dig_polishings
            platingData                   : [],
            available_cylinders           : [],
            polishMachines                : [],
        }
    );
    const history = useHistory();
    let digPolishingCylinderId = props.match.params.dig_polishing_cylinder_id ? props.match.params.dig_polishing_cylinder_id : null;
    let statusCode = props.location.state;
    // console.log(statusCode)
    
    useEffect(()=>{
        if (menuId == 1) {
            userGetMethod(`${PREVIEW_URL_POLISHING}/${digPolishingCylinderId}`)
            .then(response => {
                console.log(response.data)
                // FOR JOB ORDER
                // dropDownChange([{id : response.data.jobOrder.job_id}], 'job_order_pk_id');
                let {polishing, polishMachines, digShift, shiftDutyPersons, reworkReasons} = response.data;
                setRoughCut(response.data.rough_cut_polishing_machine)
                setFineCut(response.data.fine_cut_polishing_machine)
                
                setStateData({
                    'job_no'                        : response.data.jobOrder.job_no,

                    'job_order_pk_id'               : polishing.job_order_pk_id,
                    'cylinder_id'                   : polishing.cylinder_id,
                    'rough_cut_polishing_machine_id': polishing.rough_cut_polishing_machine_id,
                    'shift_id'                      : polishing.shift_id,
                    'fine_cut_polishing_machine_id' : polishing.fine_cut_polishing_machine_id,
                    'est_duration'                  : polishing.est_duration,
                    'on_time'                       : polishing.on_time,
                    'est_end_time'                  : polishing.est_end_time,
                    'polishing_date'                : polishing.polishing_date,
                    'rework'                        : polishing.rework,
                    'rework_reason'                 : polishing.rework_reason,
                    'production_date'               : polishing.production_date,
                    'done_by'                       : polishing.done_by,
                    'chrome_cylinder_status'        : polishing.chrome_cylinder_status,
                    'a_duration'                    : polishing.a_duration,
                    'dia_after_rough_cut'           : polishing.dia_after_rough_cut,
                    'output_status'                 : polishing.output_status,
                    'dia_after_fine_cut'            : polishing.dia_after_fine_cut,
                    'action_if_output_is_not_ok'    : polishing.action_if_output_is_not_ok,
                    'a_off_time'                    : polishing.a_off_time,
                    'remarks'                       : polishing.remarks,
                    "polishing_cylinder_auto_id"    : polishing.id,
                    
                    'polishMachines'                : polishMachines,
                    'shiftData'                     : digShift, //GET DATA FROM dig_shift_master table
                    'shiftDutyPersons'              : shiftDutyPersons, //GET DATA FROM dig_shift_details table
                    'reworkReasons'                 : reworkReasons, //GET DATA FROM rework_reasons table
                });

                setIsLoading(false);
            });
        }else{
        userGetMethod(`${POLISHING_RS_URL}/${digPolishingCylinderId}/edit`)
            .then(response => {
                // FOR JOB ORDER
                dropDownChange([{id : response.data.jobOrder.job_id}], 'job_order_pk_id');
                let {polishing, polishMachines, digShift, shiftDutyPersons, reworkReasons} = response.data;
                
                setStateData({
                    'job_no'                        : response.data.jobOrder.job_no,

                    'job_order_pk_id'               : polishing.job_order_pk_id,
                    'cylinder_id'                   : polishing.cylinder_id,
                    'rough_cut_polishing_machine_id': polishing.rough_cut_polishing_machine_id,
                    'shift_id'                      : polishing.shift_id,
                    'fine_cut_polishing_machine_id' : polishing.fine_cut_polishing_machine_id,
                    'est_duration'                  : polishing.est_duration,
                    'on_time'                       : polishing.on_time == null ? moment().format('HH:mm') : polishing.on_time,
                    // 'est_end_time'                  : polishing.est_end_time,
                    'polishing_date'                : polishing.polishing_date,
                    'rework'                        : polishing.rework,
                    'rework_reason'                 : polishing.rework_reason,
                    'production_date'               : polishing.production_date,
                    'done_by'                       : polishing.done_by,
                    'chrome_cylinder_status'        : polishing.chrome_cylinder_status,
                    'a_duration'                    : polishing.a_duration,
                    'dia_after_rough_cut'           : polishing.dia_after_rough_cut,
                    'output_status'                 : polishing.output_status,
                    'dia_after_fine_cut'            : polishing.dia_after_fine_cut,
                    'action_if_output_is_not_ok'    : polishing.action_if_output_is_not_ok,
                    'a_off_time'                    : polishing.a_off_time,
                    'remarks'                       : polishing.remarks,
                    "polishing_cylinder_auto_id"    : polishing.id,
                    
                    'polishMachines'                : polishMachines,
                    'shiftData'                     : digShift, //GET DATA FROM dig_shift_master table
                    'shiftDutyPersons'              : shiftDutyPersons, //GET DATA FROM dig_shift_details table
                    'reworkReasons'                 : reworkReasons, //GET DATA FROM rework_reasons table
                });

                setIsLoading(false);
            });
        }
        userGetMethod(`${ENGRAVING_REASON}`)
        .then(response => {
            setReasons(response.data?.rework_reasons);
        })
    }, []);

    const onChangeHandler = (event) => {
        setStateData({[event.target.name]: event.target.value});
        if (event.target.name === 'output_status' && event.target.value === '0') {
            setOpenReason(true);
        }else if (event.target.name === 'output_status' && event.target.value === '1'){
            setStateData({
                rework_reason_id : ''
            });
            setOpenReason(false);
        }
    }


    const handleDurationTimeChange = (value) => {
        setDurationTimeValue(value);
      };

    useEffect(()=>{
        const startTime = moment(stateData.on_time, 'HH:mm'); // Assuming stateData.on_time is in 24-hour format
        const durationTime = moment.duration(durationTimeValue);
        const endTime = startTime.clone().add(durationTime);
        const formattedTime = endTime.format('HH:mm'); // Format time in 12-hour format with AM/PM
        setStateData({
             'est_end_time' : formattedTime,
        })
        
        
    },[durationTimeValue])

    useEffect(() => {
        if(stateData?.on_time != '' && stateData?.est_duration != '' ){
            let inputDate = moment(stateData?.on_time,"HH:mm").format("HH:mm:ss");
            var t1 =new Date (moment(inputDate, 'HH:mm:ss').toString());
            let est_inputDate = moment(stateData?.est_duration,"HH:mm").format("HH:mm:ss");
           
            var t2 =new Date (moment(est_inputDate, 'HH:mm:ss').toString());

            t1.setHours((t1.getHours() + t2.getHours()));
            t1.setMinutes((t1.getMinutes() + (t2.getMinutes())));

            setStateData({"est_end_time": moment(t1).format("HH:mm:ss")})
        }
        if(stateData?.on_time != null && stateData?.a_off_time != null ){
            let inputDate = moment(stateData?.on_time, "HH:mm").format("HH:mm:ss");
            let endDate = moment(stateData?.a_off_time, "HH:mm").format("HH:mm:ss");

            var ts = new Date(moment(inputDate, "HH:mm:ss").toString());
            var te = new Date(moment(endDate, "HH:mm:ss").toString());

            te.setHours((te.getHours() - ts.getHours()));
            te.setMinutes((te.getMinutes() - (ts.getMinutes())));
            setStateData({'a_duration': moment(te).format("HH:mm:ss")})

        }

    },[stateData?.on_time,stateData?.est_duration,stateData?.a_off_time]);

    const dropDownChange = (e, fieldName) => {
        if(e.length > 0){
            const selectedValueId = e[0].id; //job_orders.job_order_pk_id
            setDropdownData(
                (prevstate) => ({
                    ...prevstate,
                    [fieldName]: selectedValueId,
                })
            );

            userGetMethod(`${POLISHING_GET_POLISHING_DATA_BY_JOB_ID}?jobOrderId=${selectedValueId}`)
                .then(response => {
                    let { jobOrderDetails, cylindersByJobId, platingData, completePolishingData} = response.data;
                    setStateData({
                        'jobOrderDetailsData'  : jobOrderDetails, //CYLINDER DATA FROM 'job_orders' TABLE
                        'cylindersByJobId'     : cylindersByJobId, //CYLINDER DATA FROM 'factory_cylinder_supply_chains' TABLE
                        'platingData'          : platingData, //PLATING DATA FROM 'plating_tank_schedule_details' TABLE
                        'completePolishingData': completePolishingData //PLATING DATA FROM 'plating_tank_schedule_details' TABLE
                    });
                });
        }
    }

    const cylinderOnChange = (e)=>{
        let cylId = e.target.value;
        setStateData({
            'selectCylinderId' : digPolishingCylinderId != null ? digPolishingCylinderId : e.target.value
        });

        // stateData.cylindersByJobId.map((cylinder, key)=>{
        //     if(stateData.cylindersByJobId[key]['id'] == cylId){
        //     }
        // })
    }
    
    const submitHandler = (data,e) => {
        data.polishing_cylinder_auto_id = stateData.polishing_cylinder_auto_id;
        data.cylinder_id = stateData.cylinder_id;
        data.done_by = stateData.done_by;
        data.est_duration = durationTimeValue;
       
        // console.log(data)
        userPutMethod(`${POLISHING_RS_URL}/${digPolishingCylinderId}`, data)
        .then(response => {
            if (response.data.status == 1) {
                toast.success(response.data.message)
                e.target.reset();
                clearForm();
                history.push('/polishing/index');
                } else {
                    toast.error(response.data.message)
                }
            })
        .catch(error => toast.error(error))
    }

    const clearForm = () => {
        setStateData({
            cylinder_id                   : '',
            rough_cut_polishing_machine_id: '',
            shift_id                      : '',
            fine_cut_polishing_machine_id : '',
            est_duration                  : '',
            on_time                       : '',
            est_end_time                  : '',
            polishing_date                : '',
            rework                        : '',
            rework_reason                 : '',
            production_date               : '',
            done_by                       : '',
            chrome_cylinder_status        : '',
            a_duration                    : '',
            dia_after_rough_cut           : '',
            output_status                 : '',
            dia_after_fine_cut            : '',
            action_if_output_is_not_ok    : '',
            a_off_time                    : '',
            remarks                       : '',
            rework_reason_id              : '',
            
            job_order_id                  : '', 
            jobOrderDetailsData           : [], //STORE DATA FROM job_orders
            shiftData                     : [], //STORE DATA FROM dig_shift_master
            shiftDutyPersons              : [], //STORE DATA FROM dig_shift_details
            reworkReasons                 : [], //STORE DATA FROM rework_reasons
            cylindersByJobId              : [], //STORE DATA FROM factory_cylinder_supply_chains
            // completePolishingData         : [], //STORE DATA FROM dig_polishings
            remainingPolishingData        : [],
            allPolishingData              : [],
            available_cylinders           : [],
            polishMachines                : [],
        })
    }

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    // console.log(menuId)
    
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Polishing Form</h5>
                        </div>
                        <div className="card-body">
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                            (
                                <form onSubmit={handleSubmit(submitHandler)} className="theme-form row">
                                    <div className="col-md-8">
                                        <fieldset className="border p-2" >
                                            <legend className="w-auto text-left">Job and base Information</legend>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label required">Job Id</label>
                                                <div className="col-md-9">
                                                    
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="job_no" 
                                                        ref={register({
                                                            required: 'On Time Field Required'
                                                        })}
                                                        
                                                        value={stateData.job_no}
                                                        // value='20211116-001'
                                                        disabled={menuId == 1 ? true : false}
                                                    />
                                                </div>

                                                <label className="col-md-3 col-form-label label-form required ">Cylinder Id</label>
                                                <div className="col-md-9">
                                                   
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={stateData.cylinder_id}
                                                        disabled={'disabled'}
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className="border p-2" >
                                            <legend className="w-auto text-left">Polishing</legend>
                                            
                                            <div className="form-row">
                                                <div className="col-md-6 row">
                                                    <label className="col-md-5 col-form-label label-form">Machine Rough Cut</label>
                                                    <div className="col-md-7">

                                                        {
                                                            menuId == 1 ? <select className="form-control" 
                                                            name="rough_cut_polishing_machine_id" ref={register({
                                                                required: 'Machine Rough Cut Field Required',
                                                              })}
                                                               value={roughCut.id} disabled onChange={onChangeHandler}>
                                                                <option value={roughCut.id}>{roughCut.machine_name}</option>
                                                                
                                                            </select>
                                                            :
                                                             <select className="form-control" 
                                                        name="rough_cut_polishing_machine_id" ref={register({
                                                            required: 'Machine Rough Cut Field Required',
                                                          })}
                                                           value={stateData.rough_cut_polishing_machine_id ? stateData.rough_cut_polishing_machine_id : ''} onChange={onChangeHandler}>
                                                            <option value=''>select one</option>
                                                            {
                                                                stateData.polishMachines.map((machine, key)=>(
                                                                    <option value={machine.id} key={key}>{machine.machine_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        }

                                                         
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">Machine Fine Cut</label>
                                                    <div className="col-md-7">

                                                        {
                                                        menuId == 1 ? <select className="form-control" name="fine_cut_polishing_machine_id" ref={register({ required: true })} required value={fineCut.id} disabled onChange={onChangeHandler}>
                                                            <option value={fineCut.id}>{fineCut.machine_name}</option>
                                                            
                                                        </select> 
                                                        :
                                                        <select className="form-control" name="fine_cut_polishing_machine_id" ref={register({ required: true })} required value={stateData.fine_cut_polishing_machine_id ? stateData.fine_cut_polishing_machine_id : ''} onChange={onChangeHandler}>
                                                        <option value=''>select one</option>
                                                        {
                                                            stateData.polishMachines.map((machine, key)=>(
                                                                <option value={machine.id} key={key}>{machine.machine_name}</option>
                                                            ))
                                                        }
                                                    </select>  }

                                                        
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">On Time</label>
                                                    <div className="col-md-7">
                                                        <input 
                                                            type="time" 
                                                            className="form-control" 
                                                            name="on_time" 
                                                            ref={register({
                                                                required: 'On Time Field Required'
                                                            })}
                                                            disabled={menuId == 1 ? true : false}
                                                            value={stateData.on_time ? stateData.on_time : moment().format('HH:mm')}
                                                            onChange={onChangeHandler}
                                                        />
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">Polishing Date</label>
                                                    <div className="col-md-7">
                                                        <input 
                                                            type="date" 
                                                            className="form-control" 
                                                            name="polishing_date" 
                                                            ref={register({required: true })}
                                                            disabled={menuId == 1 ? true : false}
                                                            value={stateData.polishing_date ? stateData.polishing_date : moment().format('YYYY-MM-DD')}
                                                            onChange={onChangeHandler}
                                                        />
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">Production Date</label>
                                                    <div className="col-md-7">
                                                        <input type="date" className="form-control" 
                                                        disabled={menuId == 1 ? true : false} name="production_date" ref={register({ required: true })} value={stateData.production_date ? stateData.production_date : moment().format('YYYY-MM-DD')} onChange={onChangeHandler}/>
                                                    </div>
                                                </div>      

                                                <div className="col-md-6 row">
                                                    <label className="col-md-5 col-form-label label-form">Shift</label>
                                                    <div className="col-md-7">
                                                        <input type="text" disabled='disabled' value={stateData.shiftData.shift_type == 1 ? 'Day' : stateData.shiftData.shift_type == 2 ? 'Evening' : 'Night'} className="form-control"/>

                                                        <input type="hidden" value={stateData.shiftData.shift_pk_id} className="form-control" name="shift_id" ref={register({ required: true })}/>
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">Est, Duration</label>
                                                    {/* <div className="col-md-7">
                                                        <input type="text" placeholder='hh:mm' className="form-control" name="est_duration" ref={register({ required: true })} disabled={menuId == 1 ? true : false} value={stateData.est_duration ? stateData.est_duration : ''} onChange={onChangeHandler}/>
                                                    </div> */}

                                                                <div className="col-md-7">
                                                                <TimePicker
                                                                   onChange={handleDurationTimeChange}
                                                                   value={durationTimeValue}
                                                                    id="est_cycle_duration" 
                                                                    name="est_duration" 
                                                                    format='HH:mm'
                                                                    clearIcon={null}
                                                                    disableClock
                                                                    ref={register({
                                                                        required: 'Est Duration Field Required'
                                                                    })}
                                                                    />
                                                                </div>
                                                    {/* <label className="col-form-label label-form pull-right">hh:mm</label> */}
                                                
                                                    <label className="col-md-5 col-form-label label-form">Est, End Time</label>
                                                    <div className="col-md-7">
                                                        {/* <input type="time" className="form-control" name="est_end_time" disabled={menuId == 1 ? true : false} ref={register({ required: true })} value={stateData.est_end_time ? stateData.est_end_time : ''} onChange={onChangeHandler}/> */}

                                                        <input 
                                                            type="time" 
                                                            className="form-control" 
                                                            name="est_end_time" 
                                                            ref={register({
                                                                required: 'On Time Field Required'
                                                            })}
                                                            disabled={menuId == 1 ? true : false}
                                                            value={stateData.est_end_time ? stateData.est_end_time : moment().format('HH:mm')}
                                                            onChange={onChangeHandler}
                                                        />
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className="border p-2" >
                                            <legend className="w-auto text-left">Output, QC and Remarks</legend>
                                            <div className="form-row">
                                                <div className="col-md-6 row">
                                                    <label className="col-md-5 col-form-label label-form">Chrome Cylinder? </label>
                                                    <div className="col-md-7">
                                                        <input type="checkbox" className="mt-2" name="chrome_cylinder_status" {...register("chrome_cylinder_status", { required: "Please enter your first name." })} disabled={menuId == 1 ? true : false}  onChange={onChangeHandler} />
                                                    </div>
                                                    
                                                    <label className="col-md-5 col-form-label label-form"> Dia after Rough Cut</label>
                                                    <div className="col-md-7">
                                                        <input type="text" className="form-control" name="dia_after_rough_cut" disabled={menuId == 1 ? true : false} ref={register({ required: true })} value={stateData.dia_after_rough_cut ? stateData.dia_after_rough_cut : ''} onChange={onChangeHandler} />
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">Dia after Fine Cut</label>
                                                    <div className="col-md-7">
                                                        <input type="text" className="form-control" name="dia_after_fine_cut" disabled={menuId == 1 ? true : false} ref={register({ required: true })} value={stateData.dia_after_fine_cut ? stateData.dia_after_fine_cut : ''} onChange={onChangeHandler} />
                                                    </div>
                                                
                                                    <label className="col-md-5 col-form-label label-form">A. off Time</label>
                                                    <div className="col-md-7">
                                                        <input type="time" className="form-control" name="a_off_time" disabled={menuId == 1 ? true : false} ref={register({})} value={stateData.a_off_time ? stateData.a_off_time : moment().format('HH:mm')} onChange={onChangeHandler} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 row">
                                                    <label className="col-md-5 col-form-label label-form">A. Duration</label>
                                                    <div className="col-md-7">
                                                        <input type="text"  placeholder="hh:mm" className="form-control" disabled={menuId == 1 ? true : false} name="a_duration" ref={register({})} value={stateData.a_duration ? stateData.a_duration : ''} onChange={onChangeHandler} />
                                                    </div>
                                                    {/* <label className="col-form-label label-form pull-right">hh:mm</label> */}
                                                    
                                                    <label className="col-md-5 col-form-label label-form">Output Status</label>
                                                    <div className="col-md-7">
                                                        <select className="form-control" name="output_status" ref={register({})} disabled={menuId == 1 ? true : false} defaultValue={stateData.output_status ? stateData.output_status : ''} onChange={onChangeHandler}>
                                                            <option>select one</option>
                                                            <option value="1">Ok</option>
                                                            <option value="0">Not Ok</option>
                                                        </select>
                                                    </div>
                                                    
                                                   {openReason === true ?  <>
                                                    <label className="col-md-5 col-form-label label-form">Action if output is not OK</label>
                                                    <div className="col-md-7">
                                                        {/* <input type="text" className="form-control" name="action_if_output_is_not_ok" ref={register({})} value={stateData.action_if_output_is_not_ok ? stateData.action_if_output_is_not_ok : ''} disabled={menuId == 1 ? true : false} onChange={onChangeHandler}/> */}
                                                        <select
                                                            className="form-control"
                                                            onChange={onChangeHandler}
                                                            name="rework_reason_id"
                                                            required
                                                            ref={register({})} value={stateData.rework_reason_id ? stateData.rework_reason_id : ''}>
                                                                <option value=''>select one</option>
                                                                {reasons.map((item,index) => (
                                                                <option key={index} value={item?.id}>
                                                                    {item?.reason}
                                                                </option>))}
                                                        </select>
                                                    </div>
                                                    </>
                                                    : ''}
                                                    
                                                    <label className="col-md-5 col-form-label label-form">Remarks</label>
                                                    <div className="col-md-7">
                                                        <input type="text" className="form-control" name="remarks" ref={register({})} value={stateData.remarks ? stateData.remarks : ''} disabled={menuId == 1 ? true : false} onChange={onChangeHandler}/>
                                                    </div>
                                                </div>
                                            </div>       
                                        </fieldset>
                                    </div>
                                    <div className="col-md-4">
                                        <pre className="helper-classes m-t-10">
                                            <div className="display-div">
                                                <div className='p-0'>
                                                    <table className="table table-bordernone">
                                                        <tbody>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td width="45%" align="right">Job Name</td>
                                                                <td width="5%">:</td>
                                                                <td width="50%">{stateData.jobOrderDetailsData.job_name}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">Job Type</td>
                                                                <td>:</td>
                                                                <td>{stateData.jobOrderDetailsData.job_type}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">Cylinder Id</td>
                                                                <td>:</td>
                                                                <td>{stateData.cylinder_id}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">FL</td>
                                                                <td>:</td>
                                                                <td>{stateData.jobOrderDetailsData.fl}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">Cir</td>
                                                                <td>:</td>
                                                                <td>{stateData.jobOrderDetailsData.cir}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">Dia</td>
                                                                <td>:</td>
                                                                <td>{stateData.jobOrderDetailsData.dia}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">S. Area</td>
                                                                <td>:</td>
                                                                <td>{stateData.jobOrderDetailsData.surface_area}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td align="right">Dia after Grinding</td>
                                                                <td>:</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="right">Dia-Final (Desired)</td>
                                                                <td>:</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="right">Des. Polishing</td>
                                                                <td>:</td>
                                                                <td></td>
                                                            </tr> */}
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">Remarks</td>
                                                                <td>:</td>
                                                                <td>{stateData.jobOrderDetailsData.remarks}</td>
                                                            </tr>
                                                            <tr style={{background: 'none',border:'none'}}>
                                                                <td align="right">Cylinder Type</td>
                                                                <td>:</td>
                                                                <td>{stateData.rework == 1 ? <span className="text-danger"> Rework </span> : 'New'}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                            </div>
                                        </pre>
                                    </div>
                                    {menuId == 1 ? '' : <SubmitButton link="polishing/index" menuId={ menuId } />}
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Edit;