import React from 'react'
import ShiftControl from '../../../common/ShiftControl'

const PlantingShiftControl = () => {
  return (
    <div>
        <ShiftControl value='plating_shift'></ShiftControl>
    </div>
  )
}

export default PlantingShiftControl