import React, { Fragment, useState, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShowButton, SubmitButton } from '../../common/GlobalButton';
import { JOB_ORDER_RSURL,GET_JOB_ORDER, GET_JOB_CLIENT_MARKETING, GET_JOB_CLIENT_ADDRESS, JOB_RE_AGREEMENT_RSURL, JOB_RE_AGREEMENT_RSURL_DETAILS } from '../../../api/userUrl';
import { userGetMethod, userPostMethod, userPutMethod } from '../../../api/userAction';
import useForm from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Modal from 'react-bootstrap/Modal';
import { trStyleNormal } from '../jobAgreement/Create';
import Show from './Show';


const Add = (props) => {
  const { handleSubmit, register,reset, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({});
  const [multipleDropdownData, setMultipleDropdownData] = useState([]);
  const [clientEmployee, setClientEmployee] = useState([]);
  const [typeheadOptions, setTypeheadOptions] = useState({});
  const [linkjob, setLinkjob] = useState(false);
  const [jobOrderType, setJobOrderType] = useState(null);
  const [jobOrderDetailsData,setJobOrderDetailsData] = useState([])
  const [jobOrderColors,setJobOrderColors] = useState([])
  const [printingWidth,setPrintingWidth] = useState(0);
  const [printingHeight,setPrintingHeight] = useState(0);
  const [extraFaceLength,setExtraFaceLength] = useState(0);
  const [circumference,setCircumference] = useState(0);
  const [surfaceArea,setSurfaceArea] = useState(0);
  const [totalSurfaceArea,setTotalSurfaceArea] = useState(0);
  const [flArea,setFlArea] = useState(0);
  const [cirArea,setCirArea] = useState(0);
  const [dirArea,setDirArea] = useState(0);
  const [dropDownText,setDropDownText] = useState('');
  const [clientAddress,setClientAddress] = useState('');
  const [marketingPersonArray,setMarketingPersonArray] = useState([]);
  const [jobSubClasses,setjobSubClassesArray] = useState([]);
  const [printersArray,setPrintersArray] = useState([]);
  const [previousColorArray,setPreviousColorArray] = useState([]);
  const [show, setShow] = useState(false);
  const [showId,setShowId] = useState(0)

const handleClose = () => setShow(false);

const handleShow = () => {
  setShow(true)
  
};

useEffect(()=>{
  setShowId()
},[setShowId])

const jobOrderId = props.match.params.reAgreementId;
  


// console.log(showId);
  
  // const [jobId, setJobId] = useState(0);
  const [typeAheadValue, setTypeAheadValue] = useState({
    'reference_job': [],
    'job_sub_class_id': [],
    'client_id': [],
    'printer_id': [],
    'marketing_person_id': [],
    'design_machine_id': []

  });
  

  let [calculationValue, setCalculationValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'design_width': '0',
      'ups': '0',
      'design_height': '0',
      'rpt': '0',
      'printing_height': '0',
      'circumference': '0',
      'face_length': '0',
      'total_cylinder_qty': '0',
    }
  );

  // const [ jobInfo, setJobInfo] = useState({
  //     'jobName': ''
  // });
  
  useEffect(() => {
    userGetMethod(`${JOB_RE_AGREEMENT_RSURL_DETAILS}?jobOrderId=${jobOrderId}`)
    .then((response) => {
      setJobOrderDetailsData(response.data.jobOrderView);
      setPreviousColorArray(response.data.colors);
      setLinkjob(response.data.Designer_id)
      setMultipleDropdownData(response.data.colors)
    })
  },[])

  let marketingJob ;
  let subClassJob ;
  let employeeJob_id;

  useEffect(() => {
    userGetMethod(`${JOB_ORDER_RSURL}/create`)
      .then(response => {
        // FOR MARKETING PERSON
        // console.log(response);
        setMarketingPersonArray(response.data.marketingPersons)
        setjobSubClassesArray(response.data.jobSubClasses)
        setClientEmployee(response.data.clients)
        setPrintersArray(response.data.printers)
      
        let additionalColorOptions = [];
        if (response.data.colors && response.data.colors.length > 0) {
          response.data.colors.map(color => {
            let colorObj = {};
            colorObj.id = color.id;
            colorObj.color_name = color.color_name;
            additionalColorOptions.push(colorObj);
          })
        }

        
        setTypeheadOptions(
          (prevstate) => ({
            ...prevstate,
            ['additional_colors']: additionalColorOptions,
            
          })
        );

        setIsLoading(false);
      });

      // console.log(typeAheadValue);
  }, []);

  if (marketingPersonArray.length > 0) {
    marketingJob = marketingPersonArray.filter((marketer)=> marketer.id == jobOrderDetailsData.marketing_person_id)
    employeeJob_id = marketingPersonArray.filter((marketer)=> marketer.id == jobOrderDetailsData.design_machine_id)
    subClassJob = jobSubClasses.filter((subClass)=> subClass.id == jobOrderDetailsData.job_sub_class_id)
  }

//  useEffect(() => {
//   const colorArray = [{id:10262,name:'Cyan'},{id:10267,name:'S RED'}]
//  setMultipleDropdownData(colorArray)
//  },[])


  const dropDownChange = (event, stateName) => {
    if (stateName == 'reference_job' || stateName == 'job_sub_class_id' || stateName == 'client_id' || stateName == 'printer_id' || stateName == 'marketing_person_id' || stateName == 'design_machine_id') {
      setTypeAheadValue({ [stateName]: event })
    }
    if (stateName == 'client_id' && event.length > 0) {
      const clientId = event[0].id;
      userGetMethod(`${GET_JOB_CLIENT_MARKETING}?client_id=${clientId}`)
      .then(response => {
        setClientEmployee([response.data.marketingPerson])
        

        setIsLoading(false);
      })
      userGetMethod(`${GET_JOB_CLIENT_ADDRESS}?client_id=${clientId}`)
      .then(response => {
        setClientAddress(response.data.clientAddress);
        setIsLoading(false);
      })
      
    }
    
    if (event.length > 0) {
      const selectedValue = event[0].id;
      // console.log(selectedValue);
      // setDropDownText(stateName)
      setDropdownData(
        (prevstate) => ({
          ...prevstate,
          [stateName]: selectedValue,
        })
      );
    }
  }




  useEffect(() => {
    if (clientEmployee.length > 0) {
      let designMachineOptions = [];
          if (clientEmployee &&clientEmployee.length > 0) {
            clientEmployee.map(designMachine => {
              let designMachineObj = {};
              if (designMachine !== null) {
                designMachineObj.id = designMachine.id;
                designMachineObj.name = designMachine.name;
                designMachineOptions.push(designMachineObj);
              }
              else{
                toast.error('Employee Id not Set.Need to Config first!');

              }
            })
          }
          setTypeheadOptions(
            (prevstate) => ({
              ...prevstate,
              
              ['design_machines']: designMachineOptions,
            })
            );
         
   }
   
  },[clientEmployee]);



  // console.log(clientAddress)
  // console.log(typeAheadValue?.client_id);

  const handleTypeaheadInputChange = (text) => {
    setDropDownText(text); // Store the typed text in the state
  };

  useEffect(()=>{
    if (dropDownText.length > 3) {
      // console.log(dropDownText)
      // setIsLoading(true)
      userGetMethod(`${GET_JOB_ORDER}?searchText=${dropDownText}`)
      .then(response => {
        // console.log(response.data)
        if(response.data.jobOrders){
          let referenceJobsOptions = [];
          if (response.data.jobOrders && response.data.jobOrders.length > 0) {
            response.data.jobOrders.map(referenceJob => {
              // console.log(referenceJob.id)
              // console.log(referenceJob.job_name)
            let referenceJobObj = {};
            referenceJobObj.id = referenceJob.id;
            referenceJobObj.job_no = referenceJob.job_no;
            referenceJobObj.name = referenceJob.job_name;
            referenceJobsOptions.push(referenceJobObj);
          })
        }

        setTypeheadOptions(
          (prevstate) => ({
            ...prevstate,
            
            ['reference_jobs']: referenceJobsOptions
          })
        );
        setIsLoading(false);
        }
      })
    }
    
      // setIsLoading(false);
    
    // else if(dropDownText.length < 3){
    //   setTypeheadOptions(
    //     (prevstate) => ({
    //       ...prevstate,
          
    //       ['reference_jobs']: ''
    //     })
    //   );

    // }
  },[dropDownText])
  // console.log(typeheadOptions)
  // calculated area=================
 
  // console.log(printingWidth)

  const calculateFormValue = (event) => {
    event.preventDefault();
    setCalculationValue(
      { ...calculationValue,[event.target.name]: event.target.value },
    );
  };

  useEffect(()=>{
    const printing_width_val = jobOrderDetailsData.ups * jobOrderDetailsData.design_width;
    setPrintingWidth(printing_width_val);

    const printing_height_val= (jobOrderDetailsData.design_height * jobOrderDetailsData.rpt);
    setPrintingHeight(printing_height_val);

    const circumference_val=(jobOrderDetailsData.design_height * jobOrderDetailsData.rpt)
    setCircumference(circumference_val);

    const extra_face_length_val = jobOrderDetailsData.face_length - jobOrderDetailsData.design_width * jobOrderDetailsData.ups
    setExtraFaceLength(extra_face_length_val);

    const surface_val=((jobOrderDetailsData.face_length * (jobOrderDetailsData.design_height * jobOrderDetailsData.rpt)) / 100);
    setSurfaceArea(surface_val);

    const total_surface_val=((multipleDropdownData.length * (jobOrderDetailsData.face_length * (jobOrderDetailsData.design_height * jobOrderDetailsData.rpt))) / 100)
    setTotalSurfaceArea(total_surface_val);

    const fl_val=(jobOrderDetailsData.face_length);
    setFlArea(fl_val);

    const cirArea_val=(jobOrderDetailsData.design_height * jobOrderDetailsData.rpt);
    setCirArea(cirArea_val);

    const dirArea_val=(Math.round((jobOrderDetailsData.design_height * jobOrderDetailsData.rpt) / Math.PI));
    setDirArea(dirArea_val);





  },[setPrintingWidth,setPrintingHeight,setDirArea,setCirArea,setFlArea,setTotalSurfaceArea,setSurfaceArea,setExtraFaceLength,setCircumference,multipleDropdownData.length,jobOrderDetailsData]);
  

  const submitHandler = (data, e) => {
    // console.log(data)
    e.preventDefault();
    // data.client_id = dropdownData.client_id;
    // data.job_sub_class_id = dropdownData.job_sub_class_id;
    // data.reference_job = dropdownData.reference_job;
    // data.marketing_person_id = typeheadOptions.design_machines[0]?.id;
    // data.printer_id = dropdownData.printer_id;
    // data.design_machine_id = dropdownData.design_machine_id;
    data.previous_quantity = previousColorArray.length;
    data.jobOrder_auto_id = jobOrderId;
    data.job_type = jobOrderDetailsData.job_type || '';
    let color_id_final_arr = [];
    multipleDropdownData.map(item => {
      color_id_final_arr.push(item.id);
    })
    data.color_id = color_id_final_arr;
    userPutMethod(`${JOB_RE_AGREEMENT_RSURL}/${jobOrderId}`, data)
      .then(response => {
        setIsLoading(true)
        if (response.data.status == 1 && response.data?.job_id) {
          // e.target.reset();
          const job_id = response.data?.job_id;
          reset();
          clearForm();
          marketingJob = '' ;
          subClassJob = '' ;
          employeeJob_id='';
          setIsLoading(false);
          

          if (job_id) {
            toast.success(response.data.message);
            setShowId(job_id)
            handleShow();
            
          }
          

        } else {
          toast.error(response.data.message);
        }
      })
      .catch(error => toast.error(error));
  }

  const clearForm = () => {
    setTypeAheadValue({
      'reference_job': [],
      'job_sub_class_id': [],
      'client_id': [],
      'printer_id': [],
      'marketing_person_id': [],
      'design_machine_id': []
    })
    setJobOrderDetailsData([])
    setClientAddress('')
    setPreviousColorArray([])
    setTypeheadOptions({
    'marketing_persons': [],
    'printers': [],
    'clients': [],
    'job_sub_classes': [],
    'reference_jobs': [],
    'additional_colors': [],
  'design_machines': [],})
    setCalculationValue({
      'design_width': '',
      'ups': '',
      'design_height': '',
      'rpt': '',
      'printing_height': '',
      'circumference': '',
      'face_length': '',
      'total_cylinder_qty': '',
    });
    setLinkjob(false);
    setMultipleDropdownData([]);

  }

  var menuId = 0;
  if (props.location.state === undefined) {
    var menuId = 0;
  } else {
    menuId = props.location.state.params.menuId;
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Job Order Re-Agreement</h5>
              </div>
              <div className="card-body">
                {isLoading ? (
                  <img
                    src={process.env.PUBLIC_URL + "/preloader.gif"}
                    alt="Data Loading"
                  />
                ) : (
                  <form
                    onSubmit={handleSubmit(submitHandler)}
                    className="theme-form"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <fieldset className="border">
                          <legend className="w-auto text-left">Basic</legend>

                          <div className="display-div">
                                <table className="table table-bordernone">
                                  <tbody>
                                    <div class="row">
                                      <div className="col-md-6">
                                          <tr style={trStyleNormal}>
                                            <td align="right">P/O</td>
                                            <td>:</td>
                                            <td>{jobOrderDetailsData.po}</td>
                                            </tr>
                                          <tr style={trStyleNormal}>
                                            <td align="right">Job Order Type</td>
                                            <td>:</td>
                                            <td>{jobOrderDetailsData.job_type}</td>
                                            </tr>
                                          <tr style={trStyleNormal}>
                                            <td align="right">Refference Job</td>
                                            <td>:</td>
                                            <td>{jobOrderDetailsData.reference_job}</td>
                                            </tr>
                                          <tr style={trStyleNormal}>
                                            <td align="right">Sub Class</td>
                                            <td>:</td>
                                            <td>{jobOrderDetailsData.sub_class}</td>
                                            </tr>
                                          <tr style={trStyleNormal}>
                                            <td align="right">Printer name</td>
                                            <td>:</td>
                                            <td>{jobOrderDetailsData.printer_name}</td>
                                            </tr>
                                        </div>

                                      <div className="col-md-6">

                                      <tr style={trStyleNormal}>
                                          <td align="right">Job Name</td>
                                          <td>:</td>
                                          <td>{jobOrderDetailsData.job_name}</td>
                                        </tr>
                                      <tr style={trStyleNormal}>
                                        <td align="right">Client Name</td>
                                        <td>:</td>
                                        <td>{jobOrderDetailsData.client_name}</td>
                                      </tr>
                                      <tr style={trStyleNormal}>
                                        <td align="right">Employee</td>
                                        <td>:</td>
                                        <td>{jobOrderDetailsData.marketing_person_name}</td>
                                      </tr>
                                      <tr style={trStyleNormal}>
                                        <td align="right">Designer Name</td>
                                        <td>:</td>
                                        <td>{linkjob.name}</td>
                                      </tr>
                                      <tr style={trStyleNormal}>
                                        <td align="right">Previous Quantity</td>
                                        <td>:</td>
                                        <td>{previousColorArray.length}</td>
                                      </tr>
                                     
                                        </div>
                                    </div>
                                  </tbody>
                                </table>
                          </div>

                          
                        </fieldset>

                        <fieldset className="border">
                          <legend className="w-auto text-left">Color</legend>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label required"
                              htmlFor="color_id"
                            >
                              Color
                            </label>
                            <div className="col-sm-8">
                              <Typeahead
                                id="color_id"
                                name="color_id"
                                labelKey={(option) => `${option.color_name}`}
                                multiple
                                options={typeheadOptions["additional_colors"]}
                                placeholder="Select Color..."
                                onChange={setMultipleDropdownData}
                                selected={multipleDropdownData}
                                {...register("color_id")}
                                inputProps={{
                                  required:
                                    multipleDropdownData &&
                                    multipleDropdownData == ""
                                      ? true
                                      : false,
                                }}
                              />
                              {errors.color_id && (
                                <p className="text-danger">
                                  {errors.color_id.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label" htmlFor="eye_mark_color">
                              Eye Mark Color
                            </label>
                            <div className="col-sm-8">
                              <select
                                className="form-control"
                                id="eye_mark_color"
                                name="eye_mark_color"
                                ref={register({})}
                                defaultValue=""
                              >
                                <option value="">Select One</option>
                                <option value="White">White</option>
                                <option value="Black">Black</option>
                                <option value="Red">Red</option>
                              </select>
                              
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label"
                              htmlFor="eye_mark_size"
                            >
                              Eye Mark Size
                            </label>
                            <div
                              className="col-sm-8 row"
                              style={{ marginLeft: "1px" }}
                            >
                              <div className="col-sm-5">
                                <input
                                  className="form-control"
                                  id="eye_mark_size_one"
                                  name="eye_mark_size_one"
                                  type="text"
                                  placeholder="Eye Mark Size"
                                  ref={register({})}
                                />
                                
                              </div>
                              <span className="m-r-10">X</span>
                              <div className="col-sm-6">
                                <input
                                  className="form-control"
                                  id="eye_mark_size_two"
                                  name="eye_mark_size_two"
                                  type="text"
                                  placeholder="Eye Mark Size"
                                  ref={register({})}
                                />
                                
                              </div>
                            </div>
                          </div>
                        </fieldset>


                      </div>

                      <div className="col-md-6">
                        <fieldset className="border">
                          <legend className="w-auto text-left">
                            Cylinder Info
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="design_width"
                                >
                                  Job Width (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="design_width"
                                    name="design_width"
                                    required
                                    type="text"
                                    placeholder="Job Width"
                                    value={jobOrderDetailsData.design_width}
                                    // value={jobOrderData.design_width}
                                    onChange={calculateFormValue}
                                    ref={register({
                                      required: "Job Width Field Required",
                                    })}
                                  />
                                  {errors.design_width && (
                                    <p className="text-danger">
                                      {errors.design_width.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="ups"
                                >
                                  UPS
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="ups"
                                    name="ups"
                                    required
                                    type="text"
                                    placeholder="UPS"
                                    value={jobOrderDetailsData.ups}
                                    // value={jobOrderData.ups}
                                    onChange={calculateFormValue}
                                    ref={register({
                                      required: "UPS Field Required",
                                    })}
                                  />
                                  {errors.ups && (
                                    <p className="text-danger">
                                      {errors.ups.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="printing_width"
                                >
                                  Printing Width (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="printing_width"
                                    name="printing_width"
                                    required
                                    type="text"
                                    placeholder="Printing Width"
                                    value={
                                    jobOrderDetailsData.printing_width ? jobOrderDetailsData.printing_width : printingWidth
                                    }
                                    readOnly={"readonly"}
                                    ref={register({
                                      required: "Printing Width Field Required",
                                    })}
                                  />
                                  {errors.printing_width && (
                                    <p className="text-danger">
                                      {errors.printing_width.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="face_length"
                                >
                                  Face Length (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="face_length"
                                    name="face_length"
                                    required
                                    type="text"
                                    placeholder="FL"
                                    value={jobOrderDetailsData.face_length}
                                    onChange={calculateFormValue}
                                    // value={jobOrderData.face_length}
                                    ref={register({
                                      required: "Face Length Field Required",
                                    })}
                                  />
                                  {errors.face_length && (
                                    <p className="text-danger">
                                      {errors.face_length.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="extra_face_length"
                                >
                                  Extra Face Length (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="extra_face_length"
                                    name="extra_face_length"
                                    required
                                    type="text"
                                    placeholder="Extra Width"
                                    readOnly={"readonly"}
                                    value={jobOrderDetailsData.extra_face_length? jobOrderDetailsData.extra_face_length: extraFaceLength}
                                    
                                    ref={register({
                                      required: "Extra Width Field Required",
                                    })}
                                  />
                                  {errors.extra_face_length && (
                                    <p className="text-danger">
                                      {errors.extra_face_length.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="design_height"
                                >
                                  Job Height (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="design_height"
                                    name="design_height"
                                    required
                                    type="text"
                                    placeholder="Job Height"
                                    value={jobOrderDetailsData.design_height}
                                    onChange={(e)=>calculateFormValue(e)}
                                    ref={register({
                                      required: "Job Height Field Required",
                                    })}
                                  />
                                  {errors.design_height && (
                                    <p className="text-danger">
                                      {errors.design_height.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="rpt"
                                >
                                  RPT
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="rpt"
                                    name="rpt"
                                    required
                                    type="text"
                                    placeholder="RPT"
                                    value={jobOrderDetailsData.rpt}
                                    onChange={(e)=>calculateFormValue(e)}
                                    ref={register({
                                      required: "RPT Field Required",
                                    })}
                                  />
                                  {errors.rpt && (
                                    <p className="text-danger">
                                      {errors.rpt.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="circumference"
                                >
                                  Circumference (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="circumference"
                                    name="circumference"
                                    required
                                    type="text"
                                    placeholder="Circumference"
                                    value={
                                      jobOrderDetailsData.circumference ? jobOrderDetailsData.circumference : circumference
                                    }
                                    readOnly={"readonly"}
                                    ref={register({
                                      required: "Circumference Field Required",
                                    })}
                                  />
                                  {errors.circumference && (
                                    <p className="text-danger">
                                      {errors.circumference.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-sm-8 col-form-label required"
                                  htmlFor="printing_height"
                                >
                                  Printing Height (mm)
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    id="printing_height"
                                    name="printing_height"
                                    required
                                    type="text"
                                    placeholder="Printing Height"
                                    readOnly={"readonly"}
                                    value={
                                     jobOrderDetailsData.printing_height ? jobOrderDetailsData.printing_height : printingHeight
                                    }
                                    ref={register({
                                      required:
                                        "Printing Height Field Required",
                                    })}
                                  />
                                  {errors.printing_height && (
                                    <p className="text-danger">
                                      {errors.printing_height.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <fieldset className="border">
                          <legend className="w-auto text-left">
                            Surface Area
                          </legend>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label required"
                              htmlFor="cylinder_type"
                            >
                              Cylinder Type
                            </label>
                            <div className="col-sm-8">
                              <select
                                className="form-control"
                                required
                                id="cylinder_type"
                                name="cylinder_type"
                                ref={register({
                                  required: "Cylinder Type Field Required",
                                })}
                                value={jobOrderDetailsData.cylinder_type}
                              >
                                <option value=""> Select One</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                              </select>
                              {errors.cylinder_type && (
                                <p className="text-danger">
                                  {errors.cylinder_type.message}
                                </p>
                              )}
                            </div>
                          </div>
                          
                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label required"
                              htmlFor="total_cylinder_qty"
                            >
                              Cylinder Qty
                            </label>
                            <div className="col-sm-8">
                              <input
                                className="form-control"
                                id="total_cylinder_qty"
                                name="total_cylinder_qty"
                                required
                                type="text"
                                placeholder="Please enter color"
                                value={
                                  multipleDropdownData.length > 0
                                    ? multipleDropdownData.length
                                    : ""
                                }
                                onChange={ calculateFormValue}
                                ref={register({
                                  required: "Cylinder Qty Field Required",
                                })}
                                disabled
                              />
                              {errors.total_cylinder_qty && (
                                <p className="text-danger">
                                  {errors.total_cylinder_qty.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label required"
                              htmlFor="printing_status"
                            >
                              Printing Status
                            </label>
                            <div className="col-sm-8">
                              <select
                                className="form-control"
                                required
                                id="printing_status"
                                name="printing_status"
                                ref={register({
                                  required: "Printing Status Field Required",
                                })}
                                value={jobOrderDetailsData.printing_status}
                              >
                                <option value=""> Select One</option>
                                <option value="Surface">Surface</option>
                                <option value="Reverse">Reverse</option>
                              </select>
                              {errors.printing_status && (
                                <p className="text-danger">
                                  {errors.printing_status.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label required"
                              htmlFor="surface_area"
                            >
                              Surface Area (cm²)
                            </label>
                            <div className="col-sm-8">
                              <input
                                className="form-control"
                                id="surface_area"
                                name="surface_area"
                                required
                                type="text"
                                placeholder="Face Length"
                                readOnly={"readonly"}
                                value={
                                surfaceArea
                                }
                                ref={register({
                                  required: "Face Length Field Required",
                                })}
                              />
                              {errors.surface_area && (
                                <p className="text-danger">
                                  {errors.surface_area.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-4 col-form-label required"
                              htmlFor="total_surface_area"
                            >
                              Total Surface Area (cm²)
                            </label>
                            <div className="col-sm-8">
                              <input
                                className="form-control"
                                id="total_surface_area"
                                name="total_surface_area"
                                required
                                type="text"
                                placeholder="Total Surface Area"
                                value={
                                   totalSurfaceArea
                                }
                                ref={register({
                                  required: "Total Surface Area Field Required",
                                })}
                              />
                              {errors.total_surface_area && (
                                <p className="text-danger">
                                  {errors.total_surface_area.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-6 col-form-label required"
                                  htmlFor="fl"
                                >
                                  FL (mm)
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    className="form-control"
                                    id="fl"
                                    name="fl"
                                    required
                                    type="text"
                                    placeholder="FL"
                                    disabled="disabled"
                                    value={flArea}
                                  />
                                  {errors.fl && (
                                    <p className="text-danger">
                                      {errors.fl.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-6 col-form-label required"
                                  htmlFor="cir"
                                >
                                  Cir (mm)
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    className="form-control"
                                    id="cir"
                                    name="cir"
                                    required
                                    type="text"
                                    placeholder="Cir"
                                    disabled="disabled"
                                    value={
                                     cirArea
                                    }
                                  />
                                  {errors.cir && (
                                    <p className="text-danger">
                                      {errors.cir.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-6 col-form-labelb required"
                                  htmlFor="dia"
                                >
                                  Dia (mm)
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    className="form-control"
                                    id="dia"
                                    name="dia"
                                    required
                                    type="text"
                                    placeholder="Dia"
                                    disabled="disabled"
                                    value={ dirArea}
                                    // value={jobOrderData.dia}
                                    // calculationValue.design_height * calculationValue.rpt
                                    // calculationValue.face_length
                                    ref={register({
                                      required: "Dia Field Required",
                                    })}
                                  />
                                  {errors.dia && (
                                    <p className="text-danger">
                                      {errors.dia.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <fieldset className="border">
                          <legend className="w-auto text-left">Finished</legend>
                          <div className="form-group row">
                            <label
                              className={`col-sm-2 col-form-label ${jobOrderType !== "New" ? 'required' : ''} `}

                              htmlFor="remarks"
                            >
                              Remarks
                            </label>
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                id="remarks"
                                name="remarks"
                                required={
                                  jobOrderType == "New" ? false : true
                                }
                                type="text"
                                value={jobOrderDetailsData.remarks}
                                placeholder="Remarks"
                                // value={jobOrderData.remarks}
                                ref={register({})}
                              />
                              {errors.remarks && (
                                <p className="text-danger">
                                  {errors.remarks.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <SubmitButton link="reAgreement/index" menuId={menuId}/>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton style={{maxWidth:'none', width:"100%"}}>
          <Modal.Title>Job Re-Agreement Details</Modal.Title>
        </Modal.Header>
        <Modal.Body><Show showId = {showId}></Show></Modal.Body>
        
      </Modal>
        </div>

    </Fragment>
  );
};

export default Add;