import React, { useEffect } from "react";
import { Fragment } from "react";
import { AddButton, PanelRefreshIcons, PerPageBox, EditButton, ShowButton } from "../../../common/GlobalButton";
import Pagination from "react-js-pagination";
import { useState } from "react";
import { toast } from "react-toastify";
import { userGetMethod, userDeleteMethod } from "../../../../api/userAction";
import { DESIGN_LAYOUT_RSURL,userHasAccess } from "../../../../api/userUrl";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Show from "../../jobOrder/Show";
import Modal from 'react-bootstrap/Modal';
import View from "./View";
import './ListData.css';



const ListData = (props) =>  {
    const [isLoading, setIsLoading] =useState(false);
    const [hasAccess, setHasAccess] = useState({});
    const [searchText, setSearchText] = useState('');
    const [accLoad, setAccLoad] = useState(true);
    const [layoutData, setLayoutData] = useState([]);
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [ascDesc, setAscDesc] = useState(false);
    const [jobActiveStatus, setJobActiveStatus] = useState(0);
    const [show, setShow] = useState(false);
    const [showId,setShowId] = useState(0);
    const [sortParam, setSortParam] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setShowId(id)
};

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    
    useEffect(() => {
        userGetMethod(`${userHasAccess}/${menuId}`)
            .then(response => {
                setHasAccess(response.data);
                setAccLoad(false);
            })

            pageChange();
    }, [])
    // console.log(layoutData);

    useEffect(() => {
        perPageBoxChange();
    },[jobActiveStatus,perPage])

    const handleSearchText = (e) => {
        setSearchText(e);
    }

    const searchHandler = (e) => {
        setIsLoading(true);
        userGetMethod(ascDesc === true ? `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
        .then(response => {
            setCurrentPage(response.data.pendingLayouts.current_page)
            // setPerPage(response.data.pendingLayouts.per_page)
            setTotalData(response.data.pendingLayouts.total)
            setLayoutData(response?.data?.pendingLayouts?.data)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          searchHandler();
        }
      };
    const deleteHandler = (itemId, deleteLink) => {
        userDeleteMethod(deleteLink, itemId)
            .then(response => {
                if (response.data.status == 1) {
                    setIsLoading(true);
                    let newData = layoutData.filter(data => data.id != itemId);
                    setLayoutData(newData);
                    setIsLoading(false);
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => toast.error(error));
    }

    const pageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // TABLE DATA READY
        // userGetMethod(`${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}`)
        userGetMethod(ascDesc === true ? `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                console.log("res", response.data);
                setCurrentPage(response.data.pendingLayouts.current_page)
                // setPerPage(response.data.pendingLayouts.per_page)
                setTotalData(response.data.pendingLayouts.total)
                setLayoutData(response?.data?.pendingLayouts?.data)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }
    

    const perPageBoxChange = async (e) => {
        setIsLoading(true);
        
        try {
            const response = await userGetMethod(ascDesc === true ? `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            
            setCurrentPage(response.data.pendingLayouts.current_page);
            // setPerPage(response.data.pendingLayouts.per_page);
            setTotalData(response.data.pendingLayouts.total);
            setLayoutData(response?.data?.pendingLayouts?.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    // console.log(layoutData);
    useEffect(()=>{
        let ascUrl = '';
        if (ascDesc === true) {
            ascUrl = `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`;
        } else {
            ascUrl = `${DESIGN_LAYOUT_RSURL}?layout_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`;
        }
        
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascUrl)
            .then(response => {
                setCurrentPage(response.data.pendingLayouts.current_page)
                // setPerPage(response.data.pendingDesignToFactories.per_page)
                setTotalData(response.data.pendingLayouts.total)
                setLayoutData(response.data.pendingLayouts?.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    },[ascDesc])
    
    const sortHandler = (params) => {
        setAscDesc(prevAscDesc => !prevAscDesc);
        setSortParam(params)
        // let ascUrl = '';
        // if (ascDesc === true) {
        //     ascUrl = `${ DESIGN_LAYOUT_RSURL}?asc=${params}&desc=`;
        // } else {
        //     ascUrl = `${ DESIGN_LAYOUT_RSURL}?asc=&desc=${params}`;
        // }
        
        // setIsLoading(true);
        // // TABLE DATA READY
        // userGetMethod(ascUrl)
        //     .then(response => {
        //         console.log(response.data);
        //         setCurrentPage(response.data.pendingGrindings.current_page)
        //         // setPerPage(response.data.pendingGrindings.per_page)
        //         setTotalData(response.data.pendingGrindings.total)
        //         setLayoutData(response.data.pendingGrindings.data)
        //         setIsLoading(false)
        //     })
        //     .catch(error => console.log(error))
    }
    const handleLayoutSchedule = () => {
        let url = `${process.env.PUBLIC_URL}/pendingLayoutSchedule`;
        window.open(url, '_blank', 'height=800,width=1200');
    }
    return (
        <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5>Layout List</h5>
                                </div>
                                <div className="col-md-6">
                                    {/* <PanelRefreshIcons PanelRefresh={pageChange}/> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3">
                                <div className="input-group text-box searchBox">
                                    <input
                                        type="text"
                                        className="form-control input-txt-bx"
                                        placeholder="Type to Search..."
                                        onChange={(e) => handleSearchText(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <div className="input-group-append">
                                        <button 
                                            className="btn btn-primary btn-sm" 
                                            type="button" 
                                            onClick={searchHandler} 
                                        >Go
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-5">
                                <div className="custom-table-pagination m-r-10 d-flex justify-content-between align-items-center">
                                    <label className="mt-3">
                                        <span>
                                            <select className="form-control pagi-select" name="layout_status" onChange={(e) => setJobActiveStatus(e.target.value)} value={jobActiveStatus} >
                                            <option value="2">All Layout</option>
                                                    <option value="0">Pending Layout</option>
                                                    <option value="1">Done Layout</option>
                                            </select>
                                        </span>
                                    </label>
                                    <Button style={{marginTop:'8px'}} variant="primary" onClick={handleLayoutSchedule}> Layout SCHEDULE </Button>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <AddButton link="designLayout/add" menuId={menuId} />
                                <PerPageBox pageBoxChange={perPageBoxChange} perPage={perPage} setPerPage={setPerPage}/>
                            </div>
                        </div>
                            
                        <div className="card-body datatable-react">
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                            (
                                <div className="table-responsive">
                                    <table className="table table-border-horizontal">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="5%"  > SL.</th>
                                                <th style={{cursor: "pointer"}} scope="col" width="5%" onClick={() => sortHandler(1)}> <i className="fa fa-sort"></i> Job No.</th>
                                                <th style={{cursor: "pointer"}} scope="col" width="20%" onClick={() => sortHandler(2)}> <i className="fa fa-sort"></i> Job Name</th>
                                                <th style={{cursor: "pointer"}} scope="col" width="5%" onClick={() => sortHandler(3)}> <i className="fa fa-sort"></i> Type</th>
                                                <th style={{cursor: "pointer"}} scope="col" width="15%" onClick={() => sortHandler(4)}> <i className="fa fa-sort"></i> Client</th>   
                                                <th scope="col" width="10%" onClick={() => sortHandler(1)}> Approve</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { 
                                                layoutData.length > 0 ? 
                                                    <>
                                                        {layoutData.map((item, key) =>           
                                                            (
                                                                <tr key={key}>
                                                                    <td scope="row">{ ((key+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                    <td>{item.job_no}</td>
                                                                    <td>{item.job_name}</td>
                                                                    <td>{item.job_type}</td>
                                                                    <td>{item.client_name}</td>
                                                                    <td>
                                                                    {jobActiveStatus == 1 || jobActiveStatus == 2? 
                                                                    
                                                                <ShowButton handleShow={()=>handleShow(item.id)}   menuId={ menuId }

                                                                /> 
                                                                    
                                                                    // <Link
                                                                    //     to={{
                                                                    //         pathname: `${process.env.PUBLIC_URL}/designLayout/add`,
                                                                    //         state: { params: { menuId: menuId, jobNo: item.id ,status: 1} }
                                                                    //     }}
                                                                    //     className="btn btn-secondary btn-xs" disabled>
                                                                    //     Done
                                                                    // </Link>
                                                                    :
                                                                    <Link
                                                                        to={{
                                                                            pathname: `${process.env.PUBLIC_URL}/designLayout/add`,
                                                                            state: { params: { menuId: menuId, jobNo: item.id } }
                                                                        }}
                                                                        className="btn btn-secondary btn-xs">
                                                                        Layout
                                                                    </Link>}
                                                                    </td>
                                                                </tr>
                                                            )                
                                                        )}
                                                    </> 
                                                : <tr><td colSpan="12" className="text-center">No data found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Pagination 
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={totalData}
                                onChange={pageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                firstPageText="First"
                                lastPageText="Last"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
        <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton style={{maxWidth:'none', width:"100%"}}>
          <Modal.Title>Design Layout View Point</Modal.Title>
        </Modal.Header>
        <Modal.Body><View showId = {showId}></View></Modal.Body>
        
      </Modal>
        </div>
    </Fragment>
      );
}
export default ListData;