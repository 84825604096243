import React, { Fragment , useEffect, useReducer, useState } from 'react';
import './style.scss';
import { POLISHING_SCHEDULE_PENDING } from '../../../../api/userUrl';
import PolishingScheduleHeader from './PolishingScheduleHeader';
import { userGetMethod } from '../../../../api/userAction';

const PolishingSchedule = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [polishingSchedules, setPolishingSchedules] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    const rowHead = {
        fontSize:"15px",fontWeight:"bold"
    }
    const columnHead = {
        fontSize:"12px",fontWeight:"bold"
    }
    useEffect(()=>{
        userGetMethod(`${POLISHING_SCHEDULE_PENDING}`) 
        .then(response => {
            console.log('response', response.data);
            setPolishingSchedules(response.data.allPendingCylinders);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className=""> 
                            <PolishingScheduleHeader polishingSchedules={polishingSchedules}/>

                            <div className="mainDiv" style={{"padding": "1% 5%"}}>
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <>
                                        <div className="text-center">
                                            <h5>Pending Polishing Schedule</h5>
                                        </div>
                                        <Fragment>
                                            <div className="row">
                                                <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                                    <thead>    
                                                    <tr>
                                                    <th scope="col" width="5%" >SL.</th>
                                                    <th scope="col" width="10%">Cyl Id.</th>
                                                    <th scope="col" width="15%">Job Name</th>
                                                    <th scope="col" width="5%">FL</th>
                                                    <th scope="col" width="5%">Cir</th>
                                                    <th scope="col" width="5%">Dia</th>
                                                    <th scope="col" width="15%">Printer Name</th>                              
                                                    <th scope="col" width="10%">Plating Order</th>
                                                    <th scope="col" width="10%">Before FL</th>
                                                    <th scope="col" width="15%">Client</th>
                                                    
                                                    </tr>   
                                                    </thead>
                                                    <tbody>
                                                        {polishingSchedules.length > 0 ?
                                                            polishingSchedules.map((polishingSchedule,index)=>(    
                                                                <tr key={index}>
                                                                        <td scope="row">{ (index+1) }</td>
                                                                        <td>{polishingSchedule.cylinder_id}</td>
                                                                        <td>{polishingSchedule.job_name}</td>
                                                                        <td>{polishingSchedule.fl}</td>
                                                                        <td>{polishingSchedule.cir}</td>
                                                                        <td>{polishingSchedule.dia}</td>
                                                                        <td>{polishingSchedule.printer_name}</td>
                                                                        <td>{polishingSchedule.plating_order}</td>
                                                                        <td>{polishingSchedule.before_fl}</td>
                                                                        <td>{polishingSchedule.client_name}</td>
                                                                    </tr>  
                                                            ))
                                                        : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>    
                                        </Fragment>
                                    </>
                                )
                            } 
                            </div>  
                        </div>    
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default PolishingSchedule;
