import React, { Fragment, useEffect, useState } from 'react'
import { userGetMethod } from '../../../../api/userAction';
import {  PENDING_DECHROME_RSURL, } from '../../../../api/userUrl';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";

const DeChromeUpComming = () => {
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(25);
    const [totalData, setTotalData] = useState(0);
    const [grindingData, setGrindingData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [jobTypeStatus, setJobTypeStatus] = useState('All');
    const [searchText, setSearchText] = useState('');


    useEffect(() => {
        perPageBoxChange();
    },[jobTypeStatus,perPage])
    const perPageBoxChange = (e) => {
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(`${PENDING_DECHROME_RSURL}?job_type=${jobTypeStatus}&perPage=${perPage}&searchText=${searchText}`)
            .then(response => {
                console.log("console form pageBoxChange");
                setCurrentPage(response.data.pendingOrDoneDeChrome.current_page)
                setPerPage(response.data.pendingOrDoneDeChrome.per_page)
                setTotalData(response.data.pendingOrDoneDeChrome.total)
                setGrindingData(response.data.pendingOrDoneDeChrome.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }
    const pageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(`${PENDING_DECHROME_RSURL}?job_type=${jobTypeStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}`)
            .then(response => {
                console.log("console form pageChange");
                // console.log("res", response.data);
                setCurrentPage(response.data.pendingOrDoneDeChrome.current_page)
                setPerPage(response.data.pendingOrDoneDeChrome.per_page)
                setTotalData(response.data.pendingOrDoneDeChrome.total)
                setGrindingData(response.data.pendingOrDoneDeChrome.data)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }

    const handleSearchText = (e) => {
        setSearchText(e);
    }

    const searchHandler = (e) => {
        setIsLoading(true);
        userGetMethod(`${PENDING_DECHROME_RSURL}?job_type=${jobTypeStatus}&page=${1}&perPage=${perPage}&searchText=${searchText}`)
        .then(response => {
            setCurrentPage(response.data.pendingOrDoneDeChrome.current_page)
            setPerPage(response.data.pendingOrDoneDeChrome.per_page)
            setTotalData(response.data.pendingOrDoneDeChrome.total)
            setGrindingData(response.data.pendingOrDoneDeChrome.data)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          searchHandler();
        }
      };

  return (
    <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            
                            <div className="row">
                                <div className="col-md-3 col-lg-3">
                                    <div className="input-group text-box searchBox">
                                        <input
                                            type="text"
                                            className="form-control input-txt-bx"
                                            placeholder="Type to Search..."
                                            onChange={(e) => handleSearchText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append">
                                            <button 
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                onClick={searchHandler} 
                                            >Go
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-5">
                                    <div className="custom-table-pagination m-r-10">
                                        <label className="mt-3">
                                            <span>
                                                <select className="form-control pagi-select" name="grinding_status"
                                                 onChange={(e) => setJobTypeStatus((e.target.value))}
                                                  value={jobTypeStatus} >
                                                    <option value="All">All Type</option>
                                                    <option value="New">New</option>
                                                    <option value="Redo">Redo</option>
                                                    <option value="Remake">Remake</option>
                                                    <option value="DC/RC">DC/RC</option>
                                                </select>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%" > SL.</th>
                                                    <th scope="col" width="20%" > Job No.</th>
                                                    {/* <th scope="col" width="10%" > Agreement Date </th> */}
                                                    <th scope="col" width="40%"> Job Name</th>
                                                    {/* <th scope="col" width="10%"> Qyt<span> (Done:Total)</span></th> */}
                                                    <th scope="col" width="15%" > Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    grindingData.length > 0 ? 
                                                        <>
                                                            {grindingData.map((item, index) =>           
                                                                (
                                                                    <tr key={index}>
                                                                        <td scope="row">{ ((index+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                        <td>{item.job_no}</td>
                                                                        {/* <td>{item.agreement_date}</td> */}
                                                                        <td>{item.job_name}</td>
                                                                        {/* <td>{item.done_cyl_qty} : {item.total_cylinder_qty}</td> */}
                                                                        <td>{item.job_type}</td>
                                                                    </tr>
                                                                )                
                                                            )}
                                                        </> 
                                                    : <tr><td colSpan="12" className="text-center">No data found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination 
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalData}
                                    onChange={pageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
  )
}

export default DeChromeUpComming