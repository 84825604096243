import React from 'react'
import ShiftControl from '../../../common/ShiftControl'

const ChromeShift = () => {
  return (
    <div>
        <ShiftControl value="chrome_shift"></ShiftControl>
    </div>
  )
}

export default ChromeShift