import React from 'react'
import ShiftControl from '../../../common/ShiftControl'

const PolishingShift = () => {
  return (
    <div>
        <ShiftControl value="polishing_shift"></ShiftControl>
    </div>
  )
}

export default PolishingShift